import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import backimg from "../assets/acu5.jpg";

export default function Banner() {
  const [buttonText, setButtonText] = useState("Send Message");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setButtonText("Sending Message...");

    try {
      // Compose email body
      const mailBody = `Name: ${name}%0D%0AEmail: ${email}%0D%0AAddress: ${address}`;

      // Open mail client with pre-filled details
      window.open(
        `mailto:ktmacuphysio@gmail.com?subject=Message from ${name}&body=${mailBody}`
      );

      // Reset form fields
      setName("");
      setEmail("");
      setAddress("");

      // Show thank you message
      setShowThankYou(true);
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
      setButtonText("Send Message");
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    let timer;
    if (showThankYou) {
      timer = setTimeout(() => {
        setButtonText("Send Message");
        setIsSubmitting(false);
        setShowThankYou(false);
      }, 2000);
    }

    return () => clearTimeout(timer); // Cleanup on unmount
  }, [showThankYou]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <div className="relative">
        <div
          className="absolute inset-0 h-full w-full bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${backimg})`,
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="relative bg-primary-300 bg-opacity-65">
          <svg
            className="absolute inset-x-0 -bottom-1 text-white"
            viewBox="0 0 1160 163"
          >
            <path
              fill="currentColor"
              d="M-164 13L-104 39.7C-44 66 76 120 196 141C316 162 436 152 556 119.7C676 88 796 34 916 13C1036 -8 1156 2 1216 7.7L1276 13V162.5H1216C1156 162.5 1036 162.5 916 162.5C796 162.5 676 162.5 556 162.5C436 162.5 316 162.5 196 162.5C76 162.5 -44 162.5 -104 162.5H-164V13Z"
            ></path>
          </svg>
          <div className="relative mx-auto overflow-hidden px-4 py-16 sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8 lg:py-20">
            <div className="flex flex-col items-center justify-between xl:flex-row">
              <div className="mb-12 w-full max-w-xl xl:mb-0 xl:w-7/12 xl:pr-16">
                <h2 className="mb-10 max-wxl font-serif text-2xl font-bold    text-white sm:text-3xl sm:leading-none">
                  काठमाडौँ अकुपन्चर एण्ड फिजियोथेरापी क्लिनिक प्रा. लि.
                </h2>
                <p className="mb-4 max-w-xl text-base  text-white font md:text-lg">
                  Welcome to KATHMANDU ACUPUNCTURE & PHYSIOTHERAPY CLINIC PVT.
                  LTD. , established in 2060. We are committed to enhancing your
                  well-being through expert acupuncture and acupressure
                  services. Experience holistic care that helps you achieve
                  balance and relieve pain.
                </p>
                <button
                  onClick={() => handleNavigation("/about")}
                  aria-label=""
                  className="inline-flex items-center font-semibold tracking-wider text-white transition-colors duration-200 hover:text-teal-300"
                >
                  Learn more
                  <svg
                    className="ml-2 inline-block w-3"
                    fill="currentColor"
                    viewBox="0 0 12 12"
                  >
                    <path d="M9.707,5.293l-5-5A1,1,0,0,0,3.293,1.707L7.586,6,3.293,10.293a1,1,0,1,0,1.414,1.414l5-5A1,1,0,0,0,9.707,5.293Z"></path>
                  </svg>
                </button>
              </div>
              <div className="w-full max-w-xl xl:w-5/12 xl:px-8">
                <div className="overflow-hidden rounded-xl border-t-4 border-primary-200 bg-white p-7 shadow-2xl shadow-primary-300 sm:p-10">
                  <h3 className="mb-4 font-serif text-xl font-bold text-primary-900 sm:mb-6 sm:text-center sm:text-2xl">
                    Direct Contact Us
                  </h3>
                  <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="name"
                        className="mb-1 inline-block font-medium text-primary-900"
                      >
                        Name
                      </label>
                      <input
                        placeholder="name"
                        required
                        type="text"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-200 transition duration-200 focus:border-primary-400 focus:outline-none focus:ring"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="email"
                        className="mb-1 inline-block font-medium text-primary-900"
                      >
                        E-mail
                      </label>
                      <input
                        placeholder="example@gamil.com"
                        required
                        type="email"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-200 transition duration-200 focus:border-primary-400 focus:outline-none focus:ring"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="mb-1 sm:mb-2">
                      <label
                        htmlFor="address"
                        className="mb-1 inline-block font-medium text-primary-900"
                      >
                        Address
                      </label>
                      <input
                        placeholder="address"
                        required
                        type="text"
                        className="mb-2 h-12 w-full flex-grow appearance-none rounded border border-gray-300 bg-white px-4 shadow-sm ring-primary-200 transition duration-200 focus:border-primary-400 focus:outline-none focus:ring"
                        id="address"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                    <div className="mt-4 mb-2 sm:mb-4">
                      <button
                        type="submit"
                        className={`text-white w-full relative bg-primary-350 hover:bg-primary-300 duration-500 font-semibold rounded text-sm px-6 py-3 ${
                          isSubmitting ? "cursor-not-allowed" : ""
                        }`}
                        disabled={isSubmitting}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16px"
                          height="16px"
                          fill="#fff"
                          className="mr-2 inline"
                          viewBox="0 0 548.244 548.244"
                        >
                          <path
                            fillRule="evenodd"
                            d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.083-13.898 9.525-26.152 22.608-30.162L511.512 34.337c10.147-3.19 21.13-.93 29.422 6.12 8.28 7.027 12.983 17.31 12.983 28.176v420.43c0 11.676-5.863 22.546-15.634 28.822-9.753 6.282-21.927 7.286-32.672 2.73L311.505 434.74l-38.104 47.095c-6.317 7.928-8.324 8.591-11.424 21.366-8.073 33.02L439.145 493.907c-3.268 12.506-13.272 22.248-25.841 24.91-12.578 2.675-25.803-.826-34.75-9.145L290.33 383.605 209.7 515.557c-8.29 14.08-25.703 19.824-40.735 13.236-15.056-6.633-22.227-24.367-16.319-39.89l76.535-208.823c2.719-7.41 7.222-13.988 13.187-19.192l193.67-157.674z"
                          />
                        </svg>
                        {buttonText}
                      </button>
                    </div>
                  </form>
                  {showThankYou && (
                    <div className="mt-4 text-center text-green-600">
                      Thank you! Your message has been sent.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
